<template>
    <client-page>

        <sub-visual
        sh="회사소개"
        sc="에프원시큐리티가 여러분의 정보를 함께 보호하겠습니다."
        tabActive="비전"
        icon="/images/sub/visual/sv1-icon.svg"
        bg="/images/sub/visual/sv1.png">
        </sub-visual>


        <section class="sub-section sub-section--last">
            <v-container>
                <h3 class="sub-tit--lg mb-28 mb-lg-54" data-aos="fade-up">
                    <span class="color-point">F1 Security</span> 는 소중한 정보를 보호합니다.
                </h3>
            </v-container>

            <div class="py-60 py-lg-100 vision-bg mb-60 mb-lg-100">
                <v-container>
                    <v-img class="d-none d-md-block mx-auto" max-width="900" src="/images/sub/about/vision/vision-img.png"></v-img>
                    <v-img class="d-block d-md-none mx-auto" max-width="670" src="/images/sub/about/vision/vision-img-mo.png"></v-img>
                </v-container>
            </div>

            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <div class="radius-sm border w-100 h-100 pa-16 py-md-46 px-md-38"  data-aos="fade-right" data-aos-delay="100">
                            <h5 class="sub-tit--sm mb-8 mb-lg-14">MAN POWER</h5>
                            <p class="page-text color-gray">
                                정보보호컨설팅 전문 고급인력을 양성합니다. <br>
                                전문지식과 다양한 경험을 갖춘 우수인력을 보유하여 <br class="d-none d-lg-block">
                                고객의 요구사항에 신속하게 대응합니다. 
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="radius-sm border w-100 h-100 pa-16 py-md-46 px-md-38" data-aos="fade-right" data-aos-delay="400">
                            <h5 class="sub-tit--sm mb-8 mb-lg-14">TEAM WORK</h5>
                            <p class="page-text color-gray">
                                뛰어난 팀워크와 자율적인 조직문화를 형성합니다. <br>
                                공동의 목표설정 및 적극적인 해결능력으로 <br class="d-none d-lg-block">
                                훌륭한 팀워크를 보여줍니다.
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="radius-sm border w-100 h-100 pa-16 py-md-46 px-md-38" data-aos="fade-right" data-aos-delay="700">
                            <h5 class="sub-tit--sm mb-8 mb-lg-14">VALUE OF CUSTOMER</h5>
                            <p class="page-text color-gray">
                                높은 수준의 차별화된 컨설팅 서비스를 제공합니다. <br>
                                고객서비스 중심의 보안솔루션과 AI기술을 적용한 <br class="d-none d-lg-block">
                                첨단 솔루션을 개발하여 제공합니다.
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="radius-sm border w-100 h-100 pa-16 py-md-46 px-md-38" data-aos="fade-right" data-aos-delay="1000">
                            <h5 class="sub-tit--sm mb-8 mb-lg-14">HIGH QUALITY</h5>
                            <p class="page-text color-gray">
                                최신 보안기술동향 및 정보보호 관련 법령을 반영합니다. <br>
                                최적화된 컨설팅 방법론을 보유하고 있습니다. <br>
                                최신 정보기술의 적용으로 안정적인 정보보호 서비스를 제공합니다.
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
.vision-bg{
    background-image: url(/images/sub/about/vision/vision-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>